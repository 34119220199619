import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    color: #0e1828;
    &.grey {
        background: #ececec;
    }
    svg.background-svg {
        display: none;
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            .row-wrapper {
                display: flex;
                flex-flow: column nowrap;
                position: relative;
                width: 100%;
                &.row-one {
                    padding: 7rem 4rem 2rem 4rem;
                }
                &.row-two {
                    padding: 2rem 4rem 7rem 4rem;
                }
                .column {
                    display: block;
                    width: 100%;
                    &.full-width {
                        width: 100%;
                    }
                    .subtitle {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 3.25rem;
                        font-weight: 600;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        margin-bottom: 1.5rem;
                    }
                    h2 {
                        display: block;
                        width: 100%;
                        font-family: 'Poppins';
                        font-size: 3.8rem;
                        line-height: 1.2;
                        font-weight: 600;
                        margin: 0 0 2.4rem 0;
                        > span {
                            &.italic {
                                font-family: 'Source Serif Pro';
                                font-size: 4.4rem;
                                line-height: 1.05;
                                font-weight: 300;
                                font-style: italic;
                            }
                        }
                    }
                    .content {
                        display: block;
                        font-family: 'Poppins';
                        > p {
                            font-size: 1.4rem;
                            line-height: 1.5;
                            font-weight: 300;
                            margin-bottom: 4rem;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    &.row-one {
                        padding: 10rem 12rem 4rem 12rem;
                        align-items: center;
                    }
                    &.row-two {
                        padding: 4rem 12rem 10rem 12rem;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        overflow: hidden;
        svg.background-svg {
            display: block;
            position: absolute;
            top: 0;
            left: -15%;
            bottom: 0;
            width: auto;
            height: 100%;
            pointer-events: none;
            z-index: 0;
        }
        .outer-wrapper {
            position: relative;
            z-index: 1;
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row wrap;
                    .column {
                        width: calc(50% - 1rem);
                        margin-right: 2rem;
                        &:last-child {
                            margin-right: 0;
                        }
                        h2 {
                            font-size: 4.8rem;
                            line-height: 1.1;
                            margin-bottom: 0;
                            > span {
                                &.italic {
                                    font-size: 5.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
const WithImageStatic = ({ ...props }) => {
    return (
        <Wrapper id={props.id} className={props.background}>
            <svg className="background-svg" viewBox="0 0 272 272">
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M66.7,15.4C56.4,19.7,46,25.1,35.4,32c0,0,0,0,0,0.1"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M236,33.5c-0.1-0.2-0.2-0.3-0.2-0.3s-13.2-10-35.5-18.7"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M34.1,237.6c0.1,0.2,0.2,0.4,0.4,0.6c0,0,13.4,10.4,36,19.3"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M201.9,256.5c11-4.6,22.4-10.7,33.9-18.3c0,0,0,0,0-0.1"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M133.9,34.3c-20.6-10.2-43.2-16.8-67.2-18.9c54.3-22.7,102.7-13,133.7-0.8"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M34.1,237.6C-37.6,125,32.8,35.2,35.3,32c-2.2,11.6-3.4,23.6-3.4,35.9c0,23.3,4.2,45.7,11.9,66.3"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M226.8,137.3c8.1-21.2,12.6-44.2,12.6-68.2c0-12.2-1.1-24-3.3-35.5c4.4,5.9,70.4,98-0.2,204.7"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M201.9,256.5c-53.6,22.5-100.9,13.1-131.5,1c23.7-2.5,46.1-9.4,66.3-19.9"
                />
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M133.8,34.3c42.6,21.2,76,58,92.9,103c-16.8,43.5-49,79.2-90,100.4c-42.6-21.3-76.1-58.3-92.9-103.4
                    C60.7,91,92.9,55.4,133.8,34.3z"
                />
            </svg>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="row-wrapper row-one">
                        <div className="column">
                            <div
                                className="subtitle"
                                dangerouslySetInnerHTML={{ __html: props.subtitle }}
                            />
                            <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                        </div>
                        <div className="column">
                            <div className="content">
                                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                                <picture>
                                    <source srcSet={props.smallImageWebp} type="image/webp" />
                                    <source srcSet={props.smallImagePng} type="image/png" />
                                    <img src={props.smallImagePng} alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="row-wrapper row-two">
                        <div className="column full-width">
                            <GatsbyImage
                                image={props.image.childImageSharp.gatsbyImageData}
                                alt=""
                                objectFit="contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default WithImageStatic;
