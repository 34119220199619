import React from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #0e1828;
    color: #fff;
    &.white {
        background: #fff;
        color: #0e1828;
    }
    &.grey {
        background: #ececec;
        color: #0e1828;
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        .inner-wrapper {
            display: block;
            position: relative;
            width: 100%;
            padding: 4rem 3rem;
            .image {
                display: block;
                width: 100%;
                height: auto;
                margin-bottom: 3rem;
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    font-size: 0;
                }
            }
            .image-content-overlay {
                display: none;
            }
            .image-content {
                display: block;
                width: 100%;
                margin-bottom: 2rem;
                .title-wrapper {
                    display: block;
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    h2 {
                        font-size: 3.2rem;
                        line-height: 1.25;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
            }
            .content {
                display: block;
                width: 100%;
                margin-bottom: 2rem;
                .title-wrapper {
                    display: block;
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    h3 {
                        font-family: 'Source Serif Pro';
                        font-size: 1.6rem;
                        line-height: 1.37;
                        font-weight: 400;
                        margin-bottom: 0.5rem;
                    }
                    p {
                        font-size: 1.4rem;
                        line-height: 1.57;
                        font-weight: 400;
                        margin: 0;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                min-height: 600px;
                padding: 7rem;
                .image {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    transform: translateY(-50%);
                    margin: auto;
                    z-index: 1;
                    mask-image: url(${(props) => props.image});
                    mask-size: 640px auto;
                    mask-position: center;
                    mask-clip: border-box;
                    mask-repeat: no-repeat;
                    img {
                        max-width: 640px;
                        margin: auto;
                    }
                    .image-content-overlay {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        right: 0;
                        width: 100%;
                        transform: translateY(-50%);
                        overflow: hidden;
                        h2 {
                            display: block;
                            margin: 0;
                            font-size: 6rem;
                            line-height: 1;
                            font-weight: 600;
                            letter-spacing: 0.1em;
                            text-transform: uppercase;
                            text-align: center;
                            color: ${(props) => props.overlayTextColour};
                        }
                    }
                }
                .image-content {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    width: 100%;
                    transform: translateY(-50%);
                    overflow: hidden;
                    margin: auto;
                    z-index: 0;
                    .title-wrapper {
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        h2 {
                            display: block;
                            margin: 0;
                            font-size: 6rem;
                            line-height: 1;
                            font-weight: 600;
                            letter-spacing: 0.1em;
                            text-transform: uppercase;
                            text-align: center;
                        }
                    }
                }
                .content {
                    display: block;
                    position: absolute;
                    bottom: 7rem;
                    left: 7rem;
                    max-width: 310px;
                }
                .link-to-wrapper {
                    display: block;
                    position: absolute;
                    bottom: 7rem;
                    right: 7rem;
                    z-index: 2;
                }
            }
        }
    }
`;

const LinkTo = styled(TransitionLink)`
    display: block;
    position: relative;
    text-decoration: none;
    font-family: 'Source Serif Pro';
    font-size: 1.4rem;
    line-height: 5.4rem;
    font-weight: 400;
    font-style: italic;
    &.white {
        color: #0e1828;
    }
    &.grey {
        color: #0e1828;
    }
    svg {
        display: inline-block;
        width: auto;
        height: 5.4rem;
        margin-left: 1rem;
        vertical-align: middle;
    }
`;

const PageNavigationBlock = ({ ...props }) => {
    return (
        <Wrapper
            className={props.backgroundColour}
            image={props.image}
            overlayTextColour={props.overlayTextColour}
        >
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="image">
                        <img src={props.image} alt="" />
                        <div className="image-content-overlay">
                            <h2>{props.heading}</h2>
                        </div>
                    </div>
                    <div className="image-content">
                        <div className="title-wrapper">
                            <h2>{props.heading}</h2>
                        </div>
                    </div>
                    <div className="content">
                        <div className="title-wrapper">
                            <h3>{props.subtitle}</h3>
                        </div>
                        <div className="content-wrapper">
                            <p>{props.content}</p>
                        </div>
                    </div>
                    <div className="link-to-wrapper">
                        <LinkTo
                            className={props.backgroundColour}
                            to={props.link}
                            exit={{
                                trigger: ({ node, e, exit, entry }) => {
                                    gsap.to('.image-content-overlay h2', {
                                        y: '-100%',
                                        duration: 0.5,
                                        delay: 0.5,
                                    });
                                    gsap.to('.title-wrapper h2', {
                                        y: '-100%',
                                        duration: 0.5,
                                        delay: 0.5,
                                    });

                                    gsap.to('.link-to-wrapper', {
                                        autoAlpha: 0,
                                        duration: 0.5,
                                    });
                                    gsap.to('.content', {
                                        autoAlpha: 0,
                                        duration: 0.5,
                                    });

                                    gsap.to('.image img', {
                                        transform: 'scale(0)',
                                        duration: 0.5,
                                        delay: 1.5,
                                    });
                                },
                                length: 2,
                            }}
                            entry={{
                                delay: 2,
                            }}
                        >
                            {props.linkText}
                            <svg
                                width="56"
                                height="56"
                                viewBox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="28.186"
                                    cy="28.0894"
                                    r="26.9985"
                                    transform="rotate(-90 28.186 28.0894)"
                                    stroke="#0E1828"
                                    strokeMiterlimit="10"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M37.4084 28.5898L15.8892 28.5898L15.8892 27.5898L37.4084 27.5898V28.5898Z"
                                    fill="#0E1828"
                                />
                                <path
                                    d="M34.5153 23.3877L36.1428 28.0894L34.5153 32.7911L40.4828 28.0894L34.5153 23.3877Z"
                                    fill="#0E1828"
                                />
                            </svg>
                        </LinkTo>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default PageNavigationBlock;
