import React from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const Wrapper = styled.div`
    @keyframes titleText {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes transformImage {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes revealWidth {
        0% {
            width: 100%;
        }
        100% {
            width: 0%;
        }
    }
    @keyframes reveal {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes bounce {
        20%,
        100% {
            margin-top: 0;
        }
        0% {
            margin-top: 0;
        }
        4% {
            margin-top: -0.5rem;
        }
        8% {
            margin-top: 0.5rem;
        }
        12% {
            margin-top: -0.5rem;
        }
        16% {
            margin-top: 0.5rem;
        }
    }

    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background: ${(props) => props.backgroundColor};
    overflow: hidden;
    .outer-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        .inner-wrapper {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 12rem 3rem 3rem 3rem;
            position: relative;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                &.content-container {
                    z-index: 1;
                    span.intro {
                        display: none;
                        position: relative;
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 1.5;
                        font-weight: 600;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        color: ${(props) => props.textColor};
                        margin-bottom: 1rem;
                        opacity: 0;
                        animation: reveal 1s ease-out 1.5s forwards;
                        &:before {
                            display: block;
                            position: absolute;
                            content: '';
                            top: 50%;
                            right: calc(100% + 2rem);
                            transform: translateY(-50%);
                            width: 100%;
                            height: 1px;
                            background: ${(props) => props.textColor};
                        }
                    }
                    h1 {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 4rem;
                        line-height: 1;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: ${(props) => props.textColor};
                        margin-bottom: 4rem;
                        word-wrap: normal;
                        word-break: keep-all;
                        hyphens: none;
                        position: relative;
                        .line {
                            display: block;
                            position: relative;
                            overflow-y: hidden;
                            width: 200%;
                            span {
                                &.neon-pink {
                                    color: #e2246e;
                                }
                                &.neon-pink-gradient {
                                    background: linear-gradient(
                                        271.83deg,
                                        #874c98 12.09%,
                                        #8e4a97 14.03%,
                                        #a94192 23.73%,
                                        #bc3b8f 34.4%,
                                        #c7378d 44.1%,
                                        #cb368c 54.77%,
                                        #e32c59 75.14%,
                                        #e6273c 99.39%
                                    );
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                }
                                &.neon-blue {
                                    color: #5fddeb;
                                }
                                display: block;
                                transform: translateY(100%);
                                animation: titleText 1s ease-out forwards;
                            }
                            &:nth-child(2) {
                                span {
                                    animation: titleText 1s ease-out 0.5s forwards;
                                }
                            }
                            &:nth-child(3) {
                                span {
                                    animation: titleText 1s ease-out 1s forwards;
                                }
                            }
                        }
                    }
                    .content {
                        display: block;
                        width: 100%;
                        max-width: 360px;
                        border-left: 1px solid ${(props) => props.textColor};
                        padding-left: 2rem;
                        opacity: 0;
                        animation: reveal 1s ease-out 1.5s forwards;
                        p {
                            font-family: 'Poppins';
                            font-size: 1.4rem;
                            line-height: 1.57;
                            font-weight: 400;
                            color: ${(props) => props.textColor};
                        }
                    }
                }
                &.image-container {
                    position: relative;
                    .initial-box {
                        display: block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 110px;
                        height: 240px;
                        background: ${(props) => props.gradient};
                        &:before {
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: ${(props) => props.backgroundColor};
                            animation: revealWidth 1s ease-out forwards;
                        }
                    }
                    .image-overlay {
                        display: block;
                        position: relative;
                        width: 100%;
                        max-width: 575px;
                        margin: 0 1.5rem 1.5rem auto;
                        height: auto;
                        opacity: 0;
                        animation: reveal 0s linear 1s forwards;
                        overflow: hidden;
                        .image {
                            display: block;
                            width: 100%;
                            height: 100%;
                            transform: translateX(100%);
                            animation: transformImage 1s ease-out 1s forwards;
                            padding-right: 1rem;
                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                                margin: 0;
                            }
                        }
                    }
                }
            }
            .scroll-down-button {
                display: none;
                font-size: 0;
                width: 5.4rem;
                height: 5.4rem;
                border: none;
                border-radius: 50%;
                background: transparent;
                border: 1px solid ${(props) => props.textColor};
                margin-top: 6rem;
                padding: 0;
                svg {
                    animation: bounce 5s ease-out forwards infinite;
                    path {
                        fill: ${(props) => props.textColor};
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        .outer-wrapper {
            .inner-wrapper {
                flex-flow: row nowrap;
                padding: 12.6rem 6rem;
                .column {
                    width: 40%;
                    max-width: 750px;
                    &.content-container {
                        flex: 1 1 auto;
                        padding-left: 10rem;
                        margin-left: auto;
                        span.intro {
                            display: block;
                        }
                        h1 {
                            font-size: 12rem;
                            margin-bottom: 6rem;
                        }
                    }
                    &.image-container {
                        align-self: stretch;
                        width: 60%;
                        padding-right: 8rem;
                        margin-right: auto;
                        .initial-box {
                            width: 120px;
                            height: 383px;
                            right: 8rem;
                        }
                        .image-overlay {
                            max-width: 100%;
                            margin: 0;
                            position: absolute;
                            bottom: 1rem;
                            right: 8rem;
                            height: 100%;
                            overflow: visible;
                            .image {
                                direction: rtl;
                                img {
                                    height: 100%;
                                    width: auto;
                                    max-width: unset;
                                    margin: 0 0 0 auto;
                                }
                            }
                        }
                    }
                }
                .scroll-down-button {
                    display: block;
                }
            }
        }
    }
`;

const MastheadLandingPage = ({ ...props }) => {
    const { height } = useWindowDimensions();

    function scrollDown(height) {
        window.scrollTo({ top: height, behavior: 'smooth' });
    }

    return (
        <Wrapper
            height={height}
            imageMobile={props.imageMobile}
            imageDesktop={props.imageDesktop}
            backgroundColor={props.backgroundColor}
            textColor={props.textColor}
            gradient={props.gradient}
        >
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column content-container">
                        <span
                            className="intro"
                            dangerouslySetInnerHTML={{ __html: props.subheading }}
                        />
                        <h1 dangerouslySetInnerHTML={{ __html: props.heading }} />
                        <div className="content">
                            <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                        </div>
                        <button className="scroll-down-button" onClick={() => scrollDown(height)}>
                            Scroll down
                            <svg width="10" height="26" viewBox="0 0 10 26">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.5014 21.9402L4.5014 0.420898L5.5014 0.420898L5.5014 21.9402L4.5014 21.9402Z"
                                    fill="#0E1828"
                                />
                                <path
                                    d="M9.70306 19.0469L5.00137 20.6744L0.299677 19.0469L5.00137 25.0144L9.70306 19.0469Z"
                                    fill="#0E1828"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="column image-container">
                        <div className="initial-box"></div>
                        <div className="image-overlay">
                            <div className="image">
                                <picture>
                                    <source srcSet={props.imageWebp} type="image/webp" />
                                    <source srcSet={props.imagePng} type="image/png" />
                                    <img src={props.imagePng} alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default MastheadLandingPage;
