import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    display: block;
    width: 100%;
    background: #0e1828;
    color: #fff;
    &.white {
        color: #0e1828;
        background: #fff;
        .outer-wrapper {
            .inner-wrapper {
                .column {
                    h2 {
                        color: #0e1828;
                    }
                }
            }
        }
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            padding: 7rem 3.8rem;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                h2 {
                    display: block;
                    font-family: 'Source Serif Pro';
                    font-size: 4rem;
                    line-height: 1.25;
                    font-weight: 400;
                    font-style: italic;
                    color: #fff;
                    margin: 0 0 1rem 0;
                    max-width: 825px;
                    width: 100%;
                }
                .content {
                    display: block;
                    max-width: 825px;
                    margin: 0;
                    > p {
                        font-family: 'Poppins';
                        font-size: 1.4rem;
                        line-height: 1.35;
                        font-weight: 300;
                    }
                }
                .ctas {
                    display: none;
                    flex-flow: row wrap;
                    width: 100%;
                    margin: 2.5rem 0 0 0;
                    button {
                        display: block;
                        flex: 0 1 auto;
                        margin: 0 0.7rem 0.7rem 0;
                        border: 2px solid #fff;
                        border-radius: 26px;
                        padding: 1.5rem 3.5rem;
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 1.5;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 0.3em;
                        color: #fff;
                        -webkit-appearance: none;
                        appearance: none;
                        background: none;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                padding: ${(props) => (props.noPaddingTop ? '0 10rem 6rem 10rem' : '10rem 6rem')};
                align-items: center;
                .column {
                    .ctas {
                        display: flex;
                    }
                }
            }
        }
    }
`;

const IntroText2 = ({ ...props }) => {
    function scrollTo(id) {
        let top = document.getElementById(id).offsetTop;
        window.scrollTo({ top: top, behavior: 'smooth' });
    }

    return (
        <Wrapper className={props.background} noPaddingTop={props.noPaddingTop}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column">
                        <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                        <div className="content">
                            <p dangerouslySetInnerHTML={{ __html: props.content }} />
                        </div>
                        <div className="ctas">
                            {props.buttons.map((button, index) => {
                                return (
                                    <button key={index} onClick={() => scrollTo(button)}>
                                        {button}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default IntroText2;
