import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    display: block;
    width: 100%;
    background: #0e1828;
    color: #fff;
    &.light {
        background: #ececec;
        color: #0e1828;
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    .column {
                        border-bottom: 1px solid #0e1828;
                        h3 {
                            color: #0e1828;
                        }
                    }
                }
            }
        }
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        max-width: 1500px;
        margin-left: auto;
        margin-right: auto;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            .row-wrapper {
                display: flex;
                flex-flow: column nowrap;
                position: relative;
                width: 100%;
                padding: 0 4rem;
                .column {
                    display: block;
                    flex: 0 1 auto;
                    width: 100%;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    padding: 7rem 0 4rem 0;
                    &.one-third {
                        border-bottom: none;
                        padding: 4rem 0 0 0;
                        &:last-child {
                            padding: 4rem 0 7rem 0;
                        }
                    }
                    h3 {
                        display: block;
                        width: 100%;
                        font-family: 'Poppins';
                        font-size: 2.6rem;
                        line-height: 1.25;
                        font-weight: 400;
                        color: #fff;
                        margin: 0 0 1.5rem 0;
                        &.large {
                            max-width: 860px;
                        }
                    }
                    .content {
                        display: block;
                        font-family: 'Poppins';
                        > p {
                            font-size: 1.4rem;
                            line-height: 1.5;
                            font-weight: 300;
                            &.large {
                                font-size: 1.6rem;
                                line-height: 1.625;
                                max-width: 860px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    padding: 0 12rem;
                    .column {
                        padding: 10rem 0 4rem 0;
                        &.one-third {
                            &:last-child {
                                padding: 4rem 0 10rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row nowrap;
                    .column {
                        padding: 10rem 0 7rem 0;
                        &.one-third {
                            width: -ms-calc(33.33333% - 5rem);
                            width: calc(33.33333% - 5rem);
                            margin-right: 7.5rem;
                            padding: 7rem 0 10rem 0;
                            &:last-child {
                                margin-right: 0;
                                padding: 7rem 0 10rem 0;
                            }
                        }
                        h3 {
                            &.large {
                                font-size: 4.8rem;
                                margin: 0 0 2.5rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const BigContent1 = ({ ...props }) => {
    return (
        <Wrapper className={props.background}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="row-wrapper border-bottom">
                        <div className="column">
                            <h3
                                className="large"
                                dangerouslySetInnerHTML={{ __html: props.heading }}
                            />
                            <div className="content">
                                <p
                                    className="large"
                                    dangerouslySetInnerHTML={{ __html: props.content }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row-wrapper">
                        {props.oneThird1Heading ? (
                            <div className="column one-third">
                                <h3 dangerouslySetInnerHTML={{ __html: props.oneThird1Heading }} />
                                <div className="content">
                                    <p
                                        className="large"
                                        dangerouslySetInnerHTML={{ __html: props.oneThird1Content }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {props.oneThird2Heading ? (
                            <div className="column one-third">
                                <h3 dangerouslySetInnerHTML={{ __html: props.oneThird2Heading }} />
                                <div className="content">
                                    <p
                                        className="large"
                                        dangerouslySetInnerHTML={{ __html: props.oneThird2Content }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {props.oneThird3Heading ? (
                            <div className="column one-third">
                                <h3 dangerouslySetInnerHTML={{ __html: props.oneThird3Heading }} />
                                <div className="content">
                                    <p
                                        className="large"
                                        dangerouslySetInnerHTML={{ __html: props.oneThird3Content }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default BigContent1;
