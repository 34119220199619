import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #0E1828;
    color: #fff;
    overflow: hidden;
    svg.left,
    svg.right {
        display: none;
    }
    &.grey {
        background: #ECECEC;
        color: #0E1828;
    }
    .outer-wrapper {
        display: block;
        position: relative;
        width: 100%;
        z-index: 1;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            .column {
                display: block;
                flex: 0 1 auto;
                width: 100%;
                padding: 7rem 4rem;
                &.content-container {
                    .content {
                        display: block;
                        position: relative;
                        width: 100%;
                        span.subtitle {
                            display: block;
                            font-size: 1rem;
                            line-height: 1.5;
                            font-weight: 600;
                            letter-spacing: .3em;
                            margin-bottom: 1.5rem;
                            text-transform: uppercase;
                        }
                        h2 {
                            font-size: 3.8rem;
                            line-height: 1.05;
                            font-weight: 600;
                            margin-bottom: 2.4rem;
                        }
                        p {
                            font-size: 1.4rem;
                            line-height: 1.5;
                            font-weight: 400;
                        }
                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                            font-size: 0;
                            margin: 2rem 0 0 0;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        svg.left,
        svg.right {
            display: block;
            position: absolute;
            top: 0;
            width: auto;
            height: 100%;
            pointer-events: none;
            z-index: 0;
        }
        svg.left {
            left: 0;
        }
        svg.right {
            right: 0;
        }
        .outer-wrapper {
            position: relative;
            z-index: 1;
            .inner-wrapper {
                min-height: 600px;
                    .column {
                        &.content-container {
                            max-width: 850px;
                            margin: 0 auto;
                            text-align: center;
                            .content {
                                span.subtitle {
                                    margin-bottom: 2rem;
                                }
                                h2 {
                                    font-size: 4.8rem;
                                }
                                img {
                                    display: block;
                                    width: 100%;
                                    max-width: 100%;
                                    height: auto;
                                    margin: 2.4rem auto 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
const TextAndImage8 = ({ ...props }) => {
    return (
        <Wrapper id={props.id} className={props.background}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="column content-container">
                        <div className="content">
                            <span
                                className="subtitle"
                                dangerouslySetInnerHTML={{ __html: props.subtitle }}
                            />
                            <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                            <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                            <picture>
                                <source srcSet={props.imageWebp} type="image/webp" />
                                <source srcSet={props.imagePng} type="image/png" />
                                <img src={props.imagePng} alt="" />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
            {props.background === 'grey' ? (
                <>
                    <svg className="left" viewBox="0 0 490 600" fill="none">
                        <g clipPath="url(#clip0grey)">
                            <path
                                d="M1554.89 1232.2C1354.62 1214.75 1165.99 1159.92 994.803 1074.36C639.968 898.238 360.753 590.864 219.484 217.031C-107.098 302.597 40.8196 609.14 40.8196 809.349C40.8196 910.699 123.088 986.297 141.37 1081.83C142.201 1083.49 143.032 1084.32 143.032 1084.32C143.032 1084.32 253.555 1167.4 439.698 1239.67C698.138 1341.02 1102 1421.6 1554.89 1232.2Z"
                                fill="#F4F4F4"
                            />
                            <path
                                d="M969.873 -620.359C1139.4 -707.586 1325.54 -764.077 1523.32 -784.845C1269.03 -885.365 874.308 -965.116 427.232 -777.369C334.991 -738.324 240.258 -688.479 143.862 -624.512C143.862 -624.512 143.862 -624.512 143.862 -623.682C-445.314 265.211 105.636 1032.82 142.2 1081.83C123.918 986.294 114.777 886.605 114.777 786.085C114.777 585.877 152.172 393.976 219.483 217.859C359.09 -146.006 627.502 -444.242 969.873 -620.359Z"
                                fill="url(#paint0_linear_grey)"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_grey"
                                x1="284.737"
                                y1="424.226"
                                x2="-60.3225"
                                y2="-617.245"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#E2E2E2" />
                                <stop offset="1" stopColor="#F4F4F4" />
                            </linearGradient>
                            <clipPath id="clip0grey">
                                <rect
                                    width="490"
                                    height="600"
                                    fill="white"
                                    transform="translate(490 600) rotate(-180)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg className="right" viewBox="0 0 602 601" fill="none">
                        <g clipPath="url(#clip1grey)">
                            <path
                                d="M-952.895 -631.198C-752.624 -613.752 -563.988 -558.923 -392.803 -473.357C-37.9678 -297.24 241.247 10.134 382.516 383.967C709.098 298.401 561.18 -8.14227 561.18 -208.351C561.18 -309.701 478.912 -385.299 460.63 -480.834C459.799 -482.495 458.968 -483.326 458.968 -483.326C458.968 -483.326 348.445 -566.4 162.302 -638.675C-96.1376 -740.025 -500.002 -820.607 -952.895 -631.198Z"
                                fill="#F4F4F4"
                            />
                            <path
                                d="M-367.872 1221.36C-537.396 1308.58 -723.539 1365.07 -921.316 1385.84C-667.031 1486.36 -272.308 1566.11 174.768 1378.37C267.009 1339.32 361.742 1289.48 458.138 1225.51C458.138 1225.51 458.138 1225.51 458.138 1224.68C1047.31 335.787 496.364 -431.817 459.8 -480.831C478.082 -385.296 487.223 -285.607 487.223 -185.087C487.223 15.1212 449.828 207.022 382.517 383.139C242.91 747.004 -25.502 1045.24 -367.872 1221.36Z"
                                fill="url(#paint1_linear_grey)"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint1_linear_grey"
                                x1="5.09304"
                                y1="338.488"
                                x2="490.838"
                                y2="1004.03"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#E2E2E2" />
                                <stop offset="1" stopColor="#F4F4F4" />
                            </linearGradient>
                            <clipPath id="clip1grey">
                                <rect
                                    width="602"
                                    height="600"
                                    fill="white"
                                    transform="translate(0 0.499023)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </>
            ) : (
                <>
                    <svg className="left" viewBox="0 0 490 600" fill="none">
                        <g clipPath="url(#clip0navy)">
                            <path
                                d="M1554.89 1232.2C1354.62 1214.75 1165.99 1159.92 994.803 1074.36C639.968 898.238 360.753 590.864 219.484 217.031C-107.098 302.597 40.8196 609.14 40.8196 809.349C40.8196 910.699 123.088 986.297 141.37 1081.83C142.201 1083.49 143.032 1084.32 143.032 1084.32C143.032 1084.32 253.555 1167.4 439.698 1239.67C698.138 1341.02 1102 1421.6 1554.89 1232.2Z"
                                fill="#202D46"
                            />
                            <path
                                d="M969.873 -620.359C1139.4 -707.586 1325.54 -764.077 1523.32 -784.845C1269.03 -885.365 874.308 -965.116 427.232 -777.369C334.991 -738.324 240.258 -688.479 143.862 -624.512C143.862 -624.512 143.862 -624.512 143.862 -623.682C-445.314 265.211 105.636 1032.82 142.2 1081.83C123.918 986.294 114.777 886.605 114.777 786.085C114.777 585.877 152.172 393.976 219.483 217.859C359.09 -146.006 627.502 -444.242 969.873 -620.359Z"
                                fill="url(#paint0_linear_navy)"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_navy"
                                x1="284.737"
                                y1="424.226"
                                x2="-60.3225"
                                y2="-617.245"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#0E1828" />
                                <stop offset="1" stopColor="#446394" />
                            </linearGradient>
                            <clipPath id="clip0navy">
                                <rect
                                    width="490"
                                    height="600"
                                    fill="white"
                                    transform="translate(490 600) rotate(-180)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg className="right" viewBox="0 0 602 601" fill="none">
                        <g clipPath="url(#clip1navy)">
                            <path
                                d="M-952.895 -631.198C-752.624 -613.752 -563.988 -558.923 -392.803 -473.357C-37.9678 -297.24 241.247 10.134 382.516 383.967C709.098 298.401 561.18 -8.14227 561.18 -208.351C561.18 -309.701 478.912 -385.299 460.63 -480.834C459.799 -482.495 458.968 -483.326 458.968 -483.326C458.968 -483.326 348.445 -566.4 162.302 -638.675C-96.1376 -740.025 -500.002 -820.607 -952.895 -631.198Z"
                                fill="#202D46"
                            />
                            <path
                                d="M-367.872 1221.36C-537.396 1308.58 -723.539 1365.07 -921.316 1385.84C-667.031 1486.36 -272.308 1566.11 174.768 1378.37C267.009 1339.32 361.742 1289.48 458.138 1225.51C458.138 1225.51 458.138 1225.51 458.138 1224.68C1047.31 335.787 496.364 -431.817 459.8 -480.831C478.082 -385.296 487.223 -285.607 487.223 -185.087C487.223 15.1212 449.828 207.022 382.517 383.139C242.91 747.004 -25.502 1045.24 -367.872 1221.36Z"
                                fill="url(#paint1_linear_navy)"
                            />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint1_linear_navy"
                                x1="5.09304"
                                y1="338.488"
                                x2="490.838"
                                y2="1004.03"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#0E1828" />
                                <stop offset="1" stopColor="#446394" />
                            </linearGradient>
                            <clipPath id="clip1navy">
                                <rect
                                    width="602"
                                    height="600"
                                    fill="white"
                                    transform="translate(0 0.499023)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </>
            )}
        </Wrapper>
    );
};

export default TextAndImage8;
