import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    color: #0e1828;
    &.grey {
        background: #ececec;
    }
    .outer-wrapper {
        display: block;
        width: 100%;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            width: 100%;
            .row-wrapper {
                display: flex;
                flex-flow: column nowrap;
                position: relative;
                width: 100%;
                &.row-one {
                    padding: 7rem 4rem 2rem 4rem;
                    max-width: 1500px;
                    margin-left: auto;
                    margin-right: auto;
                }
                &.row-two {
                    padding: 0;
                }
                .column {
                    display: block;
                    width: 100%;
                    &.full-width {
                        width: 100%;
                    }
                    .subtitle {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 3.25rem;
                        font-weight: 600;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        margin-bottom: 1.5rem;
                    }
                    h2 {
                        display: block;
                        width: 100%;
                        font-family: 'Poppins';
                        font-size: 3.8rem;
                        line-height: 1.2;
                        font-weight: 600;
                        margin: 0 0 2.4rem 0;
                        > span {
                            &.italic {
                                font-family: 'Source Serif Pro';
                                font-size: 4.4rem;
                                line-height: 1.05;
                                font-weight: 300;
                                font-style: italic;
                            }
                        }
                    }
                    .content {
                        display: block;
                        font-family: 'Poppins';
                        > p {
                            font-size: 1.4rem;
                            line-height: 1.5;
                            font-weight: 300;
                            margin-bottom: 4rem;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    &.row-one {
                        padding: 10rem 12rem 10rem 12rem;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            position: relative;
            z-index: 1;
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row wrap;
                    .column {
                        width: calc(50% - 1rem);
                        margin-right: 2rem;
                        &:last-child {
                            margin-right: 0;
                        }
                        h2 {
                            font-size: 4.8rem;
                            line-height: 1.1;
                            margin-bottom: 0;
                            > span {
                                &.italic {
                                    font-size: 5.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
const WithImageStaticFullWidth = ({ ...props }) => {
    return (
        <Wrapper id={props.id} className={props.background}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="row-wrapper row-one">
                        <div className="column full-width">
                            <div
                                className="subtitle"
                                dangerouslySetInnerHTML={{ __html: props.subtitle }}
                            />
                        </div>
                        <div className="column">
                            <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                        </div>
                        <div className="column">
                            <div className="content">
                                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                                <picture>
                                    <source srcSet={props.smallImageWebp} type="image/webp" />
                                    <source srcSet={props.smallImagePng} type="image/png" />
                                    <img src={props.smallImagePng} alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>
                    <div className="row-wrapper row-two">
                        <div className="column full-width">
                            <GatsbyImage
                                image={props.image.childImageSharp.gatsbyImageData}
                                alt=""
                                objectFit="contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default WithImageStaticFullWidth;
