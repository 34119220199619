import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import MastheadLandingPage from '../components/content/mastheads/landingPage';
import IntroText2 from '../components/content/IntroText/2';
import BigContent2 from '../components/content/BigContent/2';
import WithImageStatic from '../components/content/withImage/static';
import TextAndImage8 from '../components/content/TextAndImage/8';
import TextAndImage9 from '../components/content/TextAndImage/9';
import TextAndImage10 from '../components/content/TextAndImage/10';
import WithImageStaticFullWidth from '../components/content/withImage/staticFullWidth';
import BigContent1 from '../components/content/BigContent/1';
import PageNavigationBlock from '../components/content/pageNavigationBlock';

import mastheadImageWebp from '../images/ecommerce/ecommerce-page-masthead.webp';
import mastheadImagePng from '../images/ecommerce/ecommerce-page-masthead.png';

import withImageStaticSmallWebp from '../images/ecommerce/ecommerce-page-partner-logos.webp';
import withImageStaticSmallPng from '../images/ecommerce/ecommerce-page-partner-logos.png';

import withImageStaticFullWidthSmallWebp from '../images/ecommerce/ecommerce-page-static-full-width-small.webp';
import withImageStaticFullWidthSmallPng from '../images/ecommerce/ecommerce-page-static-full-width-small.png';

import textAndImage8Webp from '../images/ecommerce/ecommerce-page-text-and-image-8.webp';
import textAndImage8Png from '../images/ecommerce/ecommerce-page-text-and-image-8.png';
import textAndImage8secondWebp from '../images/ecommerce/ecommerce-page-text-and-image-8-second.webp';
import textAndImage8secondPng from '../images/ecommerce/ecommerce-page-text-and-image-8-second.png';

import textAndImage9smallWebp from '../images/ecommerce/ecommerce-page-text-and-image-9-small.webp';
import textAndImage9smallPng from '../images/ecommerce/ecommerce-page-text-and-image-9-small.png';

import textAndImage9smallThirdWebp from '../images/ecommerce/ecommerce-page-text-and-image-9-small-third.webp';
import textAndImage9smallThirdPng from '../images/ecommerce/ecommerce-page-text-and-image-9-small-third.png';

import textAndImage10smallWebp from '../images/ecommerce/ecommerce-page-text-and-image-10-small.webp';
import textAndImage10smallPng from '../images/ecommerce/ecommerce-page-text-and-image-10-small.png';

import pageNavigationBlockImage from '../images/home/slides/home-slide-4.png';

const EcommercePage = ({ transitionStatus, ...props }) => {
    useEffect(() => {
        console.log('eCommerce Page', transitionStatus);
    }, [transitionStatus]);

    const data = useStaticQuery(graphql`
        {
            textAndImage1: file(
                name: { eq: "ecommerce-page-text-and-image-1" }
                extension: { eq: "png" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            image1ImageMobile: file(
                name: { eq: "ecommerce-image-1-mobile" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            image1ImageDesktop: file(
                name: { eq: "ecommerce-image-1-desktop" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            withImageStatic: file(
                name: { eq: "ecommerce-page-with-image-static" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            textAndImage9: file(
                name: { eq: "ecommerce-page-text-and-image-9" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            withImageStaticFullWidth: file(
                name: { eq: "ecommerce-page-static-full-width" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            textAndImage10: file(
                name: { eq: "ecommerce-page-text-and-image-10" }
                extension: { eq: "png" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            textAndImage9third: file(
                name: { eq: "ecommerce-page-text-and-image-9-third" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
        }
    `);

    return (
        <Layout path={props.path}>
            <Seo title="ECommerce" />
            <MastheadLandingPage
                subheading="E-Commerce"
                heading="<div class='line'><span>We</span></div> <div class='line'><span>accelerate</span></div> <div class='line'><span class='neon-blue'>E-Commerce</span></div>"
                content="Developed for retail, tailored for your business. No matter the size of your goals we can help your business take it’s next steps."
                imageWebp={mastheadImageWebp}
                imagePng={mastheadImagePng}
                backgroundColor="#0E1828"
                textColor="#fff"
                gradient="linear-gradient(129.16deg, #9462A5 -0.78%, #7769AD 2.66%, #5C70B3 6.1%, #4974B8 10.23%, #3E77BB 13.66%, #3A78BC 17.79%, #2BB4E9 45.31%, #67C1BC 68.01%);"
            />
            <IntroText2
                heading="Enterprise Level E-Commerce Solutions"
                content="Whether you are venturing online for the first time, looking to build on successful marketplace trading, or simply looking to improve on your existing website, our enterprise level solutions can help your business take it’s next steps…"
                buttons={[
                    'B2C',
                    'Platforms',
                    'Inventory',
                    'Payments',
                    'Fulfilment',
                    'Security',
                    'ECRM',
                    'Data',
                ]}
                noPaddingTop={true}
            />
            <BigContent2
                id="B2C"
                subtitle="B2C E-Commerce"
                heading="<span class='new-line'>We help</span> <span class='new-line'>take your business</span> <span class='italic'>to the next level.</span>"
                content="We help independent businesses and international brands trade successfully online. Whatever your company size or sector, we can help revolutionise your online business. We are ecommerce problem solvers, our solutions are built around your requirements, in order to help you achieve your objectives."
                oneThird1Heading="Experts in<br/> Ecommerce."
                oneThird1Content="We're here to speed things up for your business. With years on industry experience, we know how to consistently deliver solutions that will help drive your ecommerce offering forward."
                oneThird2Heading="Tailored to your<br/> business."
                oneThird2Content="Our solutions are entirely built around your business. It's our aim to work around your existing practices and improve them, not force a specific solution into your existing practices."
                oneThird3Heading="Motivated by your<br/> success."
                oneThird3Content="Our enthusiasm and energy comes easily as we enjoy helping you succeed. All our activity is focused on quality, and seeking measurable results and tangible gains."
                shapeColourTopLeft="#5FDDEB"
                shapeColourBottomRight="#E2246E"
            />
            <WithImageStatic
                id="Platforms"
                background="grey"
                subtitle="Platforms"
                heading="Choosing the<br/> right platform<br/> <span class='italic'>for you.</span>"
                content="We're here to provide the perfect solution for your current and future needs. We work with Shopify, Magento, Salesforce Commerce Cloud, as well as providing bespoke solutions for clients with specific high-end requirements."
                image={data.withImageStatic}
                smallImageWebp={withImageStaticSmallWebp}
                smallImagePng={withImageStaticSmallPng}
            />
            <TextAndImage8
                id="Inventory"
                background="navy"
                subtitle="Inventory"
                heading="End-to-End Inventory Management, from Purchase to Postage"
                content="We ensure that you save time and maximise efficiencies, with enterprise level solutions for inventory management. Our experience with the leading ERP and WMS providers ensures seamless supply chain processes, from purchase to postage."
                imageWebp={textAndImage8Webp}
                imagePng={textAndImage8Png}
            />
            <TextAndImage9
                id="Payments"
                background="white"
                subtitle="Payments"
                heading="Payment Providers & Processing."
                content="We work with a wide variety of payment providers and processors. This gives us the experience and tools to present your customers with the right payment solutions to maximise conversion."
                image={data.textAndImage9}
                imageSmallWebp={textAndImage9smallWebp}
                imageSmallPng={textAndImage9smallPng}
            />
            <WithImageStaticFullWidth
                id="Fulfilment"
                background="grey"
                subtitle="Fulfilment"
                heading="Delivering for you and your customers"
                content="The perfect fulfilment solution is a pivotal element to delivering customer satisfaction. We integrate and interact with the leading logistics providers in order to help you deliver the perfect ecommerce experience for your customers. "
                image={data.withImageStaticFullWidth}
                smallImageWebp={withImageStaticFullWidthSmallWebp}
                smallImagePng={withImageStaticFullWidthSmallPng}
            />
            <TextAndImage10
                id="Security"
                background="blue"
                subtitle="Security"
                heading="Our main priority is to keep you secure"
                content="Data management and system security is of utmost importance to each and every one of our clients. Our systems and solutions are tested and vetted by the biggest and the best in the industry, to ensure total compliance and ultimate peace of mind."
                image={data.textAndImage10}
                imageSmallWebp={textAndImage10smallWebp}
                imageSmallPng={textAndImage10smallPng}
            />
            <TextAndImage9
                id="ECRM"
                background="white"
                subtitle="ECRM"
                heading="Connected Customer Data"
                content="We specialise in managing customer data and marketing consent, helping you segment and automate to optimise your marketing communications."
                image={data.textAndImage9third}
                imageSmallWebp={textAndImage9smallThirdWebp}
                imageSmallPng={textAndImage9smallThirdPng}
                textOrder="2"
            />
            <TextAndImage8
                id="Data"
                background="grey"
                subtitle="Data & Analytics"
                heading="Analysing Data for Actionable Insights"
                content="We help our clients by implementing and managing the tools that allow the detailed analysis required to make data-driven business decisions."
                imageWebp={textAndImage8secondWebp}
                imagePng={textAndImage8secondPng}
            />
            {/* <BigContent1
        heading="A comprehensive service, that covers your entire ecommerce ecosystem."
        content="We pride ourselves on taking care of the user from website visit, to receipt of your product. Our fully managed service covers everything in between."
        oneThird1Heading="Payment Providers & Processing."
        oneThird1Content="We work with a wide variety of payment providers and processors. This gives us the experience and tools to present your customers with the right payment solutions to maximise conversion."
        oneThird2Heading="International Ecommerce and Cross-Border Trade."
        oneThird2Content="Our team has experience in handling multiple currencies, translations, and payment providers to allow you to trade in multiple territories."
        oneThird3Heading="Data, Analytics & Reporting."
        oneThird3Content="Our data team is always on hand to ensure you are in posession of the KPIs required to constantly evolve and improve for your users."
      /> */}
            <PageNavigationBlock
                backgroundColour="white"
                overlayTextColour="#fff"
                heading="Moving forward with you"
                subtitle="Next up - Digital Marketing"
                content="We use intelligent techniques to make informed choices, serving the right message, to the right people, at the right time."
                image={pageNavigationBlockImage}
                linkText="Learn about our Digital Marketing services"
                link="/digital-marketing"
            />
        </Layout>
    );
};

export default EcommercePage;
