import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #ececec;
    color: #0e1828;
    overflow: hidden;
    &.white {
        background: #fff;
    }
    &.blue {
        background: #0e1828;
        color: #fff;
    }
    .outer-wrapper {
        display: block;
        position: relative;
        width: 100%;
        z-index: 1;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            .row-wrapper {
                display: flex;
                flex-flow: column nowrap;
                width: 100%;
                .column {
                    display: block;
                    position: relative;
                    width: 100%;
                    &.image-container {
                        padding: 4rem 4rem 7rem 4rem;
                        background: #fff;
                        order: 2;
                    }
                    &.content-container {
                        order: 1;
                        padding: 7rem 4rem 4rem 4rem;
                    }
                    .subtitle {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 3.25rem;
                        font-weight: 600;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        margin-bottom: 1.5rem;
                    }
                    h2 {
                        display: block;
                        width: 100%;
                        font-family: 'Poppins';
                        font-size: 4rem;
                        line-height: 1.2;
                        font-weight: 600;
                        margin: 0 0 1.6rem 0;
                        span {
                            &.italic {
                                font-family: 'Source Serif Pro';
                                font-weight: 300;
                                font-style: italic;
                            }
                        }
                    }
                    .content {
                        display: block;
                        > p {
                            font-family: 'Poppins';
                            font-size: 1.4rem;
                            line-height: 1.5;
                            font-weight: 300;
                            margin-bottom: 4rem;
                            &.subtitle {
                                margin-top: 4rem;
                                margin-bottom: 1.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    .column {
                        h2 {
                            font-size: 4.8rem;
                            line-height: 1.1;
                        }
                        .content {
                            > p {
                                &.subtitle {
                                    margin-top: 6rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row wrap;
                    align-items: center;
                    min-height: 600px;
                    .column {
                        width: 50%;
                        &.image-container {
                            order: ${(props) =>
                                props.textOrder && props.textOrder === '2' ? '1' : '2'};
                            background: none;
                            padding-right: ${(props) =>
                                props.textOrder && props.textOrder === '2' ? '4rem' : '0'};
                            padding-left: ${(props) =>
                                props.textOrder && props.textOrder === '2' ? '0' : '4rem'};
                        }
                        &.content-container {
                            order: ${(props) => (props.textOrder ? props.textOrder : '1')};
                            max-width: 750px;
                            margin-left: ${(props) =>
                                props.textOrder && props.textOrder === '2' ? '0' : 'auto'};
                            margin-right: ${(props) =>
                                props.textOrder && props.textOrder === '2' ? 'auto' : '0'};
                            padding-left: 12rem;
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const TextAndImage9 = ({ ...props }) => {
    return (
        <Wrapper
            id={props.id}
            className={props.background}
            shapeColour={props.shapeColour}
            textOrder={props.textOrder}
        >
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="row-wrapper row-one">
                        <div className="column content-container">
                            <div className="content">
                                <div
                                    className="subtitle"
                                    dangerouslySetInnerHTML={{ __html: props.subtitle }}
                                />
                                <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                                <p dangerouslySetInnerHTML={{ __html: props.content }} />
                                <picture>
                                    <source srcSet={props.imageSmallWebp} type="image/webp" />
                                    <source srcSet={props.imageSmallPng} type="image/png" />
                                    <img src={props.imageSmallPng} alt="" />
                                </picture>
                            </div>
                        </div>
                        <div className="column image-container">
                            <GatsbyImage
                                image={props.image.childImageSharp.gatsbyImageData}
                                alt=""
                                objectFit="contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default TextAndImage9;
